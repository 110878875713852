define('fotobox-print-editor/components/print-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    mode: false,
    pic: false,
    text: '',
    ip: '',
    textleft: 150,
    textsize: 140,
    heightplus: 0,
    login: false,
    auftragsnummer: '',
    hideMode: false,
    hidePic: false,
    hideWelcome: false,
    hideText: false,
    showTop: false,
    showLeft: false,
    showColor: false,
    showSize: false,
    password: '',
    uploaddate: '',
    ajax: Ember.inject.service(),
    isdownloading: false,
    auftrag: '',
    text2: '',
    text2left: 150,
    text2size: 140,
    base64: '',
    teamColor: '',
    fontweight: 'bold',
    fontweight2: 'bold',
    fontweight3: 'bold',
    isLoginin: false,
    text3: '',
    fonttype: 'Calibri',
    text3size: 150,
    samplePic: false,
    telefon: '04331 - 463 93 26',
    email: 'info@photoboxkiel.de',
    individualbackgroundpath: '',
    text3left: 150,
    fontsizes: Ember.ArrayProxy.create({ content: Ember.A([40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200]) }),
    fonts: Ember.ArrayProxy.create({ content: Ember.A(['Calibri', 'Roboto', 'Bad Script', 'Open Sans Condensed', 'Indie Flower', 'Pacifico', 'Dancing Script', 'Amatic SC']) }),
    backgrounds2: Ember.ArrayProxy.create({ content: Ember.A([]) }),
    backgrounds: Ember.ArrayProxy.create({ content: Ember.A(['neu1.jpg', 'neu2.jpg', 'neu3.jpg', 'neu4.jpg', 'neu5.jpg', 'neu6.jpg', 'neu7.jpg', 'neu8.jpg', 'neu9.jpg', 'neu10.jpg', 'fischer.jpg', 'rosa1.jpg', 'rosa2.jpg', 'rosa3.jpg', 'euka1.jpg', 'schwarz.jpg', 'herz1.jpg', 'herz2.jpg', 'herz3.jpg', 'herz4.jpg', 'gruen2.jpg', 'gruen.jpg', 'weiss.jpg', 'gold.jpg', 'gray.jpg', 'tuerkis.jpg', 'christmas1.jpg', 'christmas2.jpg', 'christmas3.jpg', 'lila.jpg', 'scratched-wood.jpg', 'wei1.jpg', 'wei2.jpg', 'wei3.jpg', 'wei4.jpg', 'wei5.jpg', 'wei6.jpg', 'wei7.jpg', 'wei8.jpg', 'wei9.jpg', 'wei10.jpg', 'wei11.jpg', 'wei12.jpg', 'sil1.jpg', 'sil2.jpg', 'sil3.jpg', 'sil4.jpg', 'sil5.jpg']) }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var host = window.location.hostname;
      if (host == "buchung.photobox-hh.de") {
        this.set('telefon', '0176 - 456 040 95');
        this.set('email', 'info@photobox-hh.de');
      } else {
        this.set('telefon', '04331 - 463 93 26');
        this.set('email', 'info@photoboxkiel.de');
      }
    },

    actions: {
      showSample: function showSample(pic) {
        this.set("samplePic", pic);
      },
      setmode: function setmode(nr) {
        this.set('mode', nr);
        if (nr == 1) {
          this.set("textsize", 50);
        } else {
          this.set('textsize', 140);
          this.set('text2size', 140);
          this.set('text3size', 140);
        }
        this.set('hideDruck', true);
      },
      setpic: function setpic(pic) {
        this.set('pic', pic);
        this.set('hidePic', true);
      },
      preview: function preview() {
        this.set('hideText', true);
        // get the image
        var _self = this;
        var img = document.getElementById(this.get('pic'));
        var img2 = document.getElementById('schatten');
        var img3 = document.getElementById('mitte');
        var img4 = document.getElementById('dummy');

        // create and customize the canvas
        var canvas = document.getElementById("newimg");

        if (_self.get("mode") == 1) {
          canvas.width = 2000;
          canvas.height = 1333;
          document.body.appendChild(canvas);
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          ctx.drawImage(img4, 0, 0);
          var rectHeight = 912;
          var rectWidth = 1400;
          var rectX = 1520;
          var rectY = 1044;
          ctx.clearRect(90, 30, 1820, 1153);
          ctx.font = _self.get("fontweight") + ' ' + _self.get("textsize") + 'px ' + _self.get("fonttype");
          ctx.fillStyle = _self.get("teamColor");
          ctx.fillText(_self.get("text"), _self.get("textleft"), 1228 + parseInt(_self.get("heightplus")));

          _self.set('base64', canvas.toDataURL("image/png"));
          _self.set('base64preview', canvas.toDataURL("image/png"));
        }

        if (this.get("mode") == 4 || this.get("mode") == 3) {
          canvas.width = 2960;
          canvas.height = 2000;

          document.body.appendChild(canvas);
          // get the context
          var ctx = canvas.getContext("2d");
          // draw the image into the canvas
          ctx.drawImage(img, 0, 0);
          ctx.drawImage(img2, 0, 0);
          ctx.clearRect(41, 107, 1398, 786);
          ctx.clearRect(1520, 107, 1398, 786);
          ctx.clearRect(41, 1107, 1398, 786);
          if (this.get("mode") == 4) {

            ctx.clearRect(1520, 1107, 1398, 786);
          }

          if (this.get("mode") == 3) {
            var rectHeight = 912;
            var rectWidth = 1400;
            var rectX = 1520;
            var rectY = 1044;

            ctx.fillStyle = this.get("teamColor");
            ctx.font = this.get("fontweight") + ' ' + this.get("textsize") + 'px "' + this.get("fonttype") + '"';
            ctx.fillText(this.get("text"), rectX + this.get("textleft"), rectY + 250 + parseInt(this.get("heightplus")));
            ctx.font = this.get("fontweight2") + ' ' + this.get("text2size") + 'px "' + this.get("fonttype") + '"';
            ctx.fillText(this.get("text2"), rectX + this.get("text2left"), rectY + 500 + parseInt(this.get("heightplus")));
            ctx.font = this.get("fontweight3") + ' ' + this.get("text3size") + 'px "' + this.get("fonttype") + '"';
            ctx.fillText(this.get("text3"), rectX + this.get("text3left"), rectY + 750 + parseInt(this.get("heightplus")));
            this.set('base64', canvas.toDataURL("image/png"));
            ctx.drawImage(img3, 0, 0);

            this.set('base64preview', canvas.toDataURL("image/png"));
          } else {
            this.set('base64', canvas.toDataURL("image/png"));
            this.set('base64preview', canvas.toDataURL("image/png"));
          }
        }
      },
      changed1: function changed1(value) {
        this.set('textleft', value);
        this.send('preview');
      },
      changed2: function changed2(value) {
        this.set('text2left', value);
        this.send('preview');
      },
      changed3: function changed3(value) {
        this.set('text3left', value);
        this.send('preview');
      },
      changedheight: function changedheight(value) {

        this.set('heightplus', value);
        this.send('preview');
      },
      login: function login() {
        var _self = this;
        this.set('isLoginin', true);
        var auftragsnummer = this.get("auftragsnummer");
        var password = this.get("password");
        var loginData = this.get('ajax').request('https://api.photoboxkiel.de/login.php', {
          method: 'POST',
          data: {
            auftragsnummer: auftragsnummer,
            password: password
          }

        }).then(function (data) {

          if (data && data == "1") {
            _self.get('ajax').request('https://api.photoboxkiel.de/getauftrag.php', {
              method: 'GET',
              data: {
                auftragsnummer: auftragsnummer
              }

            }).then(function (auftrag) {

              if (auftrag && auftrag.success) {
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                  for (var _iterator = Object.keys(auftrag)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var key = _step.value;

                    _self.set(key, auftrag[key]);
                  }
                } catch (err) {
                  _didIteratorError = true;
                  _iteratorError = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                      _iterator.return();
                    }
                  } finally {
                    if (_didIteratorError) {
                      throw _iteratorError;
                    }
                  }
                }

                _self.set("mode", parseInt(auftrag.mode));
                _self.set("heightplus", parseInt(auftrag.heightplus));
                _self.set("textleft", parseInt(auftrag.textleft));
                _self.set("text2left", parseInt(auftrag.text2left));
                _self.set("text3left", parseInt(auftrag.text3left));
                if (auftrag.textsize) _self.set("textsize", parseInt(auftrag.textsize));
                if (auftrag.text2size) _self.set("text2size", parseInt(auftrag.text2size));
                if (auftrag.text3size) _self.set("text3size", parseInt(auftrag.text3size));
                _self.set("auftragsnummer", auftragsnummer);
                _self.set("password", password);
                /*    _self.set("pic", auftrag.pic);
                    _self.set("text", auftrag.text);
                    _self.set("fontweight", auftrag.fontweight);
                    
                    _self.set("text2", auftrag.text2);
                    _self.set("fontweight2", auftrag.fontweight2);
                    
                    _self.set("text3", auftrag.text3);
                    _self.set("fontweight3", auftrag.fontweight3);
                    
                    
                    
                    _self.set("teamColor", auftrag.teamColor);
                    _self.set("fonttype", auftrag.fonttype);*/
                _self.set("hideDruck", true);
                _self.set("hidePic", true);
                _self.set("hideText", true);
                _self.set("loading", true);
                _self.set('isLoginin', false);
                Ember.run.later(function () {
                  _self.set("loading", false);
                  _self.send("preview");
                }, 5000);
                Ember.run.later(function () {

                  _self.send("preview");
                }, 15000);
              }
            });
            _self.set('login', true);
            _self.set("hideLogin", true);
          } else {
            alert("Login Fehlgeschlagen, bitte probieren Sie es erneut.");
            _self.set('isLoginin', false);
          }
        });
      },
      changeSize1: function changeSize1(value) {
        this.set("textsize", value);
        this.send("preview");
      },
      changeSize2: function changeSize2(value) {
        this.set("text2size", value);
        this.send("preview");
      },
      changeSize3: function changeSize3(value) {
        this.set("text3size", value);
        this.send("preview");
      },
      setFont: function setFont(font) {
        this.set('fonttype', font);
        this.send('preview');
      },
      showSize: function showSize() {
        this.set('showSize', true);
        this.set('showColor', false);
        this.set('showTop', false);
        this.set('showLeft', false);
      },
      showColor: function showColor() {
        this.set('showSize', false);
        this.set('showColor', true);
        this.set('showTop', false);
        this.set('showLeft', false);
      },
      showTop: function showTop() {
        this.set('showSize', false);
        this.set('showColor', false);
        this.set('showTop', true);
        this.set('showLeft', false);
      },
      showLeft: function showLeft() {
        this.set('showSize', false);
        this.set('showColor', false);
        this.set('showTop', false);
        this.set('showLeft', true);
      },
      sendtosascha: function sendtosascha() {
        var check = confirm('Haben Sie alles noch einmal überprüft und wollen dieses Design senden?');
        if (check == false) {} else {

          var saveObj = {
            mode: this.get("mode"),
            pic: this.get("pic"),
            text: this.get("text"),
            fontweight: this.get("fontweight"),
            textsize: this.get("textsize"),
            text2: this.get("text2"),
            fontweight2: this.get("fontweight2"),
            text2size: this.get("text2size"),
            text3: this.get("text3"),
            fontweight3: this.get("fontweight3"),
            text3size: this.get("text3size"),
            heightplus: this.get("heightplus"),
            textleft: this.get("textleft"),
            text2left: this.get("text2left"),
            text3left: this.get("text3left"),
            teamColor: this.get("teamColor"),
            fonttype: this.get("fonttype"),
            auftragsnummer: this.get("auftragsnummer"),
            password: this.get("password"),
            key: "4fdg342f34534fg"

          };

          this.get('ajax').request('https://api.photoboxkiel.de/senddesign.php', {
            method: 'POST',
            data: saveObj
          }).then(function (data) {
            console.log("data", data);
            if (data && data.success) {
              alert('Design wurde erfolgreich verschickt');
            } else {
              alert("Fehler beim speichern. Bitte wenden Sie sich an unseren Support unter 04331 - 463 93 26");
            }
          });

          //this.set('login', false);
        }
      }
    }
  });
});