define('fotobox-print-editor/components/create-order/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        email: '',
        orderno: '',
        store: Ember.inject.service()

    });
});