define('fotobox-print-editor/components/bs-navbar/nav-item', ['exports', 'ember-bootstrap/components/bs-navbar/nav-item'], function (exports, _navItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _navItem.default;
    }
  });
});